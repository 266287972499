<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      一番賞紀錄
    </h3>
    <v-row>
      <v-col>
        <h4>篩選條件</h4>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" sm="8" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="filterQuery.date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedDate"
              v-bind="attrs"
              v-on="on"
              label="日期區間"
              outlined
              readonly
              dense
              hide-details
              clearable
              class="dates-input"
              @click:clear="filterQuery.date = []"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterQuery.date"
            range
            no-title
            scrollable
            locale="zh-tw"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(filterQuery.date)"
            >
              確定
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          label="關鍵字"
          placeholder="一番賞名稱、編號、獎項"
          v-model.trim="filterQuery.keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" sm="4" md="3">
        <v-select
          label="店點"
          placeholder="店點"
          v-model="filterQuery.branch"
          :items="branches"
          outlined
          dense
          hide-details
        >
        </v-select>
      </v-col>
      <v-col cols="6" sm="4" md="3">
        <v-select
          label="通路"
          placeholder="線上/現場"
          v-model="filterQuery.isOnline"
          :items="[
            { value: null, text: '全部' },
            { value: true, text: '線上' },
            { value: false, text: '現場' },
          ]"
          outlined
          dense
          hide-details
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <div class="font-weight-bold">金額總計：{{ dataAmountSum }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ dayjs(item.createdAt).format("YYYY/MM/DD") }}
          </template>
          <template v-slot:item.isOnline="{ item }">
            {{ ichibanMap[item.ichibanId]?.isOnline ? "線上" : "現場" }}
          </template>
          <template v-slot:item.branch="{ item }">
            {{ ichibanMap[item.ichibanId]?.branch }}
          </template>
          <template v-slot:item.ichibanKey="{ item }">
            {{ ichibanMap[item.ichibanId]?.key }}
          </template>
          <template v-slot:item.ichibanName="{ item }">
            {{ ichibanMap[item.ichibanId]?.name }}
          </template>
          <template v-slot:item.plan="{ item }">
            {{ item.plan.count }} 抽 x {{ item.plan.amount }} 元
          </template>
          <template v-slot:item.rewards="{ item }">
            <template v-if="item.rewards[0]">
              <div v-for="record in item.rewards">
                #{{ record.number }} - {{ record.reward }}
              </div>
            </template>
            <template v-else-if="item.rewards.reward">
              <div>#{{ item.rewards.number }} - {{ item.rewards.reward }}</div>
            </template>
            <div v-else>---</div>
          </template>
          <template v-slot:item.imageUrl="{ item }">
            <div
              v-if="item.imageUrl"
              class="my-1"
              @click="openDialog(item.imageUrl)"
            >
              <v-icon class="cursor-pointer">mdi-image</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.open" width="350px" hide-overlay>
      <div class="image-dialog">
        <img :src="dialog.imageUrl" />
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";
import { sortTableDate } from "@/utils/utils.js";
// import { branchOptions } from "@/config/common.js";

export default {
  name: "IchibanRecord",
  mixins: [util],
  components: {},
  data: () => ({
    dayjs,
    records: [],
    ichiban: [],
    ichibanMap: {},
    dialog: { imageUrl: null, open: false },
    menu: false,
    filterQuery: {
      date: [],
      keyword: "",
      branch: "",
      isOnline: null,
    },
    headers: [
      {
        text: "日期",
        value: "createdAt",
        align: "center",
        sort: sortTableDate,
      },
      {
        text: "通路",
        value: "isOnline",
        align: "center",
        sortable: false,
      },
      {
        text: "店點",
        value: "branch",
        align: "center",
        sortable: false,
      },
      {
        text: "一番賞編號",
        value: "ichibanKey",
        align: "center",
        sortable: false,
      },
      {
        text: "名稱",
        value: "ichibanName",
        align: "left",
        sortable: false,
      },
      {
        text: "價格",
        value: "plan",
        align: "left",
        sortable: false,
      },
      {
        text: "獎項",
        value: "rewards",
        align: "left",
        sortable: false,
      },
      {
        text: "圖片",
        value: "imageUrl",
        align: "center",
        sortable: false,
      },
    ],
  }),
  computed: {
    _: () => _,
    formattedDate() {
      const date = this.filterQuery.date;
      return date[0] && date[1]
        ? dayjs(date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(date[1]).format("YYYY-MM-DD")
        : "";
    },
    branches() {
      return [{ text: "全部", value: "" }, ...this.accessBranch];
    },
    dataAmountSum() {
      let amount = 0;
      this.filterData.forEach((record) => {
        amount += record.plan.amount;
      });
      return amount;
    },
    filterData() {
      let data = this.records.sort((a, b) => {
        if (dayjs(a.date).isAfter(dayjs(b.date))) {
          return -1;
        }
        if (dayjs(a.date).isBefore(dayjs(b.date))) {
          return 1;
        }
        return 0;
      });
      const { keyword, date, branch, isOnline } = this.filterQuery;

      if (date.length === 2) {
        data = data.filter((item) =>
          this.isBetween({
            date: item.createdAt,
            start: date[0],
            end: date[1],
          })
        );
      }
      if (keyword) {
        data = data.filter((record) => {
          const ichiban = this.ichibanMap[record.ichibanId];
          return (
            this.includesIgnoreCase(record.rewards.reward, keyword) ||
            this.includesIgnoreCase(ichiban?.name, keyword) ||
            this.includesIgnoreCase(ichiban?.key, keyword)
          );
        });
      }
      if (branch) {
        data = data.filter(
          (record) => this.ichibanMap[record.ichibanId]?.branch === branch
        );
      }
      if (isOnline === true) {
        data = data.filter(
          (record) => this.ichibanMap[record.ichibanId]?.isOnline
        );
      } else if (isOnline === false) {
        data = data.filter(
          (record) => !this.ichibanMap[record.ichibanId]?.isOnline
        );
      }

      return data;
    },
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.$vloading.show();
      await this.getIchibanRecord();
      await this.getIchiban();
      this.$vloading.hide();
    },
    async getIchibanRecord() {
      let { data } = await this.axios.get(`/ichibanRecord`);
      this.records = data.sort(this.sortDateDesc("createdAt"));
    },
    async getIchiban() {
      let { data } = await this.axios.get(`/ichiban`);
      this.ichiban = data;
      this.ichibanMap = _.keyBy(data, "_id");
    },
    clear() {
      this.dialog.open = false;
    },
    openDialog(imageUrl) {
      this.dialog.open = true;
      this.dialog.imageUrl = imageUrl;
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.dates-input {
  min-width: 150px;
}

.image-dialog {
  font-size: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
